<template>
	<screen size="small" class="login-view">
		<template slot="body">
			<div class="login-header">
				<div class="roundup-logo"></div>
			</div>
			<div class="login-content">
				<form v-on:submit.prevent="onSubmit">
					<ui-label>
						<ui-input v-model="email" placeholder="Email" />
					</ui-label>
					<ui-label>
						<ui-input
							v-model="password"
							placeholder="Password"
							type="password"
						/>
					</ui-label>
					<ui-label>
						<ui-button native-type="submit" :loading="isLoading"
							>Login</ui-button
						>
					</ui-label>
					<div class="forgot-password">
						<router-link :to="{ name: 'forgot-password' }"
							>Forgot Password?</router-link
						>
					</div>
					<div class="forgot-password">
						<router-link :to="{ name: 'register' }"
							>Register Your Organization</router-link
						>
					</div>
				</form>
			</div>
		</template>
	</screen>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'

export default {
	name: 'login-view',
	components: { UiButton, UiInput, UiLabel, Screen },
	data() {
		return {
			isLoading: false,
			email: '',
			password: ''
		}
	},
	methods: {
		...mapActions(['login']),
		...mapGetters(['isOnboarding', 'onboardingStep']),
		onSubmit() {
			this.isLoading = true
			this.login({
				email: this.email,
				password: this.password
			})
				.then(data => {
					this.isLoading = false
					this.$router.push({ name: 'steps' })
				})
				.catch(({ message }) => {
					this.isLoading = false
					this.$swal('Error', message)
				})
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.screen {
	&.login-view {
		padding-top: 60px;
		text-align: left;
		.login-header {
			p {
				margin: 0px;
			}
		}
		.roundup-logo {
			background: url('../../../assets/roundup_logo.svg');
			background-size: 137px 14px;
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			height: 14px;
			margin: 0px 0px 20px;
		}
		.forgot-password {
			font-size: 13px;
		}
	}
}
</style>
